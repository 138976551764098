import React, { FC, ReactNode } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useInvertInfoPosition } from '../../../hooks/useInvertInfoPosition';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { Card as CardComponent } from 'wix-ui-tpa/cssVars';
import { ServiceLayoutProps } from '../ServiceCard';
import {
  ImageShapeOptions,
  ServiceListLayoutOptions,
} from '../../../../../../types/types';
import { classes, st } from './Card.st.css';
import { classes as serviceCardClasses } from '../ServiceCard.st.css';
import { DataHooks as CardDataHooks } from './consts';
import { DataHooks } from '../consts';
import settingsParams from '../../../../settingsParams';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { useAddVisibilityClass } from '../../../../hooks/useAddVisibilityClass';

export const Card: FC<ServiceLayoutProps> = (props) => {
  const { image, info, index } = props;
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { serviceListLayout } = useWidgetViewModel();

  const isGrid = serviceListLayout === ServiceListLayoutOptions.GRID;
  const invertInfoPosition = useInvertInfoPosition() && !isGrid;
  const shouldAlternate =
    experiments.enabled('specs.Bookings.ImproveServiceListLayoutSettings') &&
    settings.get(settingsParams.alternateImageInfoSides) &&
    (index ?? 0) % 2 === 1;
  const shouldInvertInfoPosition = invertInfoPosition
    ? !shouldAlternate
    : shouldAlternate;

  const layoutStylePerBreakPointEnable = experiments.enabled(
    'specs.bookings.layoutStylePerBreakPoint',
  );

  const getCardContent = () => {
    return shouldInvertInfoPosition && !layoutStylePerBreakPointEnable ? (
      <>
        <Info info={info} />
        <Image image={image} />
      </>
    ) : (
      <>
        <Image image={image} />
        <Info info={info} />
      </>
    );
  };

  return (
    <CardComponent
      className={st(classes.root, {
        isMobile,
        isGrid,
        even: (index ?? 0) % 2 === 0,
        layoutStylePerBreakPointEnable,
        invertInfoPosition,
        applyRatio: !isGrid,
        flipRatio: settings.get(settingsParams.flipImageAndTextRatio),
        isRoundImage:
          settings.get(settingsParams.serviceImageShape) ===
          ImageShapeOptions.ROUND,
      })}
      stacked={isGrid}
      data-hook={DataHooks.CARD}
    >
      {getCardContent()}
    </CardComponent>
  );
};

const Image: FC<{ image: ReactNode }> = ({ image }) => {
  const { addVisibilityClass } = useAddVisibilityClass();
  return image ? (
    <CardComponent.Container
      data-hook={CardDataHooks.IMAGE}
      className={addVisibilityClass(
        classes.media,
        serviceCardClasses.serviceImageVisibility,
        'isServiceImageVisible',
      )}
    >
      {image}
    </CardComponent.Container>
  ) : null;
};

const Info: FC<{ info: ReactNode }> = ({ info }) => {
  return (
    <CardComponent.Container
      data-hook={CardDataHooks.INFO}
      className={classes.info}
    >
      {info}
    </CardComponent.Container>
  );
};
